import React, { useEffect, useState } from "react";
import { Box, Image, Link, Text } from "@chakra-ui/react";
import Action from "../Home/components/Action";
import { useParams } from "react-router-dom";
import { useGetReel } from "../../operations/reels";
import Progress from "../../components/Progress";
import PageNotFound from "../PageNotFound";
import Helmet from "react-helmet";

const styles = {
  container: {
    display: "flex",
    padding: 15,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#298267",
  },
  image: {
    width: 70,
    height: 70,
    borderRadius: 35,
    border: "2px solid white",
  },
  title: {
    fontWeight: "bolder",
    color: "#fff",
  },
  subTitle: {
    color: "#fff",
  },
  detailContainer: {
    flex: 1,
    marginLeft: 10,
    justifyContent: "center",
  },
};

const Share = () => {
  const { id } = useParams();
  const [reel, setReel] = useState({
    id: "",
    tags: [],
    video: "",
    caption: "",
    creator: { firstName: "", lastName: "", username: "", avatar: "" },
  });
  const { getReel, getReelResult } = useGetReel();

  useEffect(() => {
    if (id) {
      getReel(id).then(res => {
        if (res.data?.getReel) {
          setReel(res.data?.getReel);
        }
      });
    }
    // eslint-disable-next-line
  }, [id]);

  return getReelResult.error ? (
    <PageNotFound
      header="Oops, Reel is broken"
      content="Either this reel has been deleted or it never existed"
    />
  ) : (
    <Box w={"100vw"} position={"relative"}>
      {reel?.id && (
        <Helmet>
          <title>{`${reel?.creator?.firstName} ${reel?.creator?.lastName} on Master`}</title>
          <meta name="description" content={reel?.caption} />
          <meta
            property="og:title"
            content={`${reel?.creator?.firstName} ${reel?.creator?.lastName} on Master`}
          />
          <meta property="og:description" content={reel.caption} />
          <meta
            property="og:image"
            content={`${reel?.video?.split(".mp4")[0]}.png`}
          />
        </Helmet>
      )}
      <Box className="header" px={"0"}>
        <Text
          position={"relative"}
          zIndex={2}
          m={"0 auto"}
          fontSize={"2xl"}
          fontWeight={"bold"}
          color={"white"}
          textAlign={"center"}
        >
          Master
        </Text>
      </Box>
      <Box w={"100vw"} h={"60vh"} m={"0 auto"} position={"absolute"} top={"-1"}>
        <iframe
          style={{
            height: "100%",
            width: "100%",
          }}
          width="560"
          height="315"
          src={reel?.video}
          title={reel?.caption}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
          allowFullScreen
        ></iframe>
        <Box style={styles.container}>
          <Image
            style={styles.image}
            src={
              reel?.creator?.avatar ||
              `https://api.dicebear.com/5.x/initials/png?seed=${reel?.creator?.firstName} ${reel?.creator?.lastName}`
            }
          />
          <Box style={styles.detailContainer}>
            <Text style={styles.title}>{`${reel?.creator?.firstName || "-"} ${
              reel?.creator?.lastName || "-"
            }`}</Text>
            <Text style={styles.subTitle}>@{reel?.creator?.username}</Text>
          </Box>
        </Box>
        <Box pl={5} mt={2}>
          <Text
            fontWeight={"bold"}
            fontSize={"lg"}
            color={styles.container.backgroundColor}
          >
            Caption
          </Text>
          <Text fontSize={"md"}>{reel?.caption}</Text>
        </Box>
        <Box pl={5} mt={2}>
          <Text
            fontWeight={"bold"}
            fontSize={"lg"}
            color={styles.container.backgroundColor}
          >
            Tags
          </Text>
          <Text fontSize={"md"}>
            {reel?.tags?.map(tag => `#${tag}`).join(" ")}
          </Text>
        </Box>
        <Action
          header="Download the mobile app"
          content="Download the mobile app today, Create other videos like this and Get Paid To Teach!"
        />
        <Link
          color={styles.container.backgroundColor}
          textAlign={"center"}
          fontSize={"20px"}
          textDecoration={"underline"}
          href="/"
          display={"block"}
        >
          Go Home
        </Link>
        <Box my={"100px"} visibility={"hidden"}>
          Spacer
        </Box>
      </Box>

      <Progress loading={getReelResult.isLoading && !getReelResult.error} />
    </Box>
  );
};

export default Share;
