import { Box, Heading, Image } from "@chakra-ui/react";
import img from "../../../assets/live.png"
function LiveSessions({ slides }) {
  return (
    <Box position="relative">
      <Heading
        fontSize={["3xl", "5xl"]}
        fontWeight="bold"
        textAlign="center"
        padding={10}
        // marginBottom={30}
      >
        Go Live
      </Heading>

      <div
        style={{
          fontSize: 20,
          textAlign: "center",
        }}
        className=""
      >
        Tune in to our Expert-Led Live Sessions
      </div>

      <Image mx="auto" my={10} src={img} />
    </Box>
  );
}

export default LiveSessions;
