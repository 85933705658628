import { Box, Input, Text, Tooltip } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaClipboard, FaPaste } from "react-icons/fa";

const AccountInput = ({ label, account }) => {
  const $clipboard = navigator.clipboard;
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    copied &&
      setTimeout(() => {
        setCopied(false);
      }, 2000);
  }, [copied]);
  return (
    <div>
      <Box py={3} position="relative">
        <>
          <Text fontWeight="bold">{label}</Text>

          <Input defaultValue={account} pointerEvents="none" />

          <Tooltip
            hasArrow
            isOpen={copied}
            placement="top-end"
            closeDelay={500}
            label="Copied to Clipboard"
          >
            <Box
              onClick={() => {
                $clipboard.writeText(account);
                setCopied(true);
              }}
              position="absolute"
              top="50%"
              right={0}
              px={5}
              pt={1}
              cursor="pointer"
            >
              {copied ? <FaPaste /> : <FaClipboard />}
            </Box>
          </Tooltip>
        </>
      </Box>
    </div>
  );
};

export default AccountInput;
