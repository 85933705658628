import {
  Box,
  Heading,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import React from "react";

const faqData = [
  {
    category: "General",
    questions: [
      {
        question: "What is Master Africa?",
        answer:
          "Master Africa is an online skills marketplace offering game-based and hybrid training to enhance employability and customer engagement.",
      },
      {
        question: "What is the mission of Master Africa?",
        answer:
          "Our mission is to bridge the skills gap in Africa by providing accessible, engaging, and effective training solutions.",
      },
      {
        question: "Who can benefit from Master Africa?",
        answer:
          "Anyone seeking to improve their skills, including students, professionals, entrepreneurs, and organizations.",
      },
    ],
  },
  {
    category: "Training and Courses",
    questions: [
      {
        question: "What types of training does Master Africa offer?",
        answer:
          "Game-based, online, offline, and interactive training programs.",
      },
      {
        question: "What skills are covered?",
        answer: "Tech, Manual, Soft & Creative Skills.",
      },
    ],
  },
  {
    category: "Payment Options",
    questions: [
      {
        question: "Can I pay directly for training?",
        answer:
          "Yes, you can pay directly without participating in skill challenges.",
      },
      {
        question: "What payment options are available?",
        answer: "Credit card, mobile money, bank transfer.",
      },
    ],
  },
  {
    category: "Scholarship Rewards",
    questions: [
      {
        question: "Are there rewards for top performers?",
        answer:
          "Yes, the scholarship rewards are for top performers 1st: 100% Scholarship & training tools 2nd: 100% Scholarship 3rd: 50% Scholarship Remaining Players: 60 mins Expert-led live sessions and participatory badges",
      },
      {
        question: "How are winners selected?",
        answer: "Based on leaderboard rankings.",
      },
      {
        question: "What are the criteria for scholarship rewards?",
        answer: "Complete skill challenges, achieve top rankings.",
      },
    ],
  },
  {
    category: "Skill Challenges",
    questions: [
      {
        question: "What are skill challenges?",
        answer: "Interactive games and quizzes assessing skills.",
      },
      {
        question: "How do I participate in skill challenges?",
        answer: "Access challenges through the Master Africa app.",
      },
    ],
  },
  {
    category: "App Access",
    questions: [
      {
        question: "How do I download the Master Africa app?",
        answer: "Visit app store links, Google Play, or Apple App Store.",
      },
      { question: "Is the app free to download?", answer: "Yes." },
    ],
  },
  {
    category: "Registration",
    questions: [
      {
        question: "How do I register?",
        answer:
          "Download the app to register or click 'Register' on the website and fill out the form.",
      },
    ],
  },
  {
    category: "Career Opportunities",
    questions: [
      {
        question: "How can Master Africa enhance my employability?",
        answer:
          "Our training improves skills, boosts confidence, and provides certification.",
      },
      {
        question: "Do you offer job placement services?",
        answer:
          "We connect trained individuals with Masters who could be potential employers.",
      },
    ],
  },
  {
    category: "Technical",
    questions: [
      {
        question: "What browsers are recommended?",
        answer: "Chrome, Firefox, Safari.",
      },
      {
        question: "Is my data secure?",
        answer: "Yes, our platform uses encryption and secure protocols.",
      },
    ],
  },
  {
    category: "Support",
    questions: [
      {
        question: "How do I contact support?",
        answer:
          "Email chike@masterafrica.com, phone 08133081773, or live chat.",
      },
      { question: "What are your support hours?", answer: "Mon-Sat 9am-5pm." },
    ],
  },
  {
    category: "Partnerships and Collaborations",
    questions: [
      {
        question: "Can organizations partner with Master Africa?",
        answer: "Yes, for customized training solutions.",
      },
      {
        question: "How do I become a training partner?",
        answer: "Contact us at chike@masterafrica.com.",
      },
    ],
  },
  {
    category: "Miscellaneous",
    questions: [
      {
        question: "Is Master Africa available in multiple languages?",
        answer: "Not yet but soon.",
      },
      {
        question: "Can I access Master Africa offline?",
        answer: "No, internet connection is required.",
      },
      {
        question: "How do I stay updated on Master Africa news?",
        answer: "Follow our social media, newsletter, or blog.",
      },
    ],
  },
];

function FAQ() {
  return (
    <Box w="100%" maxW="800px" mx="auto" p={5}>
      <Heading as="h2" mb={6} textAlign="center">
        Frequently Asked Questions
      </Heading>
      {faqData.map((section, index) => (
        <Box key={index} mb={8}>
          <Heading as="h3" size="lg" mb={4}>
            {section.category}
          </Heading>
          <Accordion allowToggle>
            {section.questions.map((faq, idx) => (
              <AccordionItem key={idx}>
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      {faq.question}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>{faq.answer}</AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Box>
      ))}
    </Box>
  );
}

export default FAQ;
