import { useMutation } from "react-query";
import { query } from "../api";
import { gql } from "@apollo/client";

export const useGetReel = () => {
  const getReel = async (id = "") => {
    if (id) {
      return await query({
        query: gql`
          query getReel($id: ObjectId!) {
            getReel(id: $id) {
              id
              video
              creator {
                firstName
                lastName
                username
                avatar
              }
              caption
              tags
            }
          }
        `,
        variables: {
          id,
        },
      });
    }
  };

  const { mutateAsync, ...getReelResult } = useMutation(getReel);

  return { getReel: mutateAsync, getReelResult };
};
