import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Modal as ModalContainer,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";

export default function Modal({ status, onClose, isOpen, title, content }) {
  return (
    <ModalContainer
      isCentered
      onClose={onClose}
      isOpen={isOpen}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <Alert
          status={status}
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          height="200px"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            {title}
          </AlertTitle>
          <AlertDescription maxWidth="sm">{content}</AlertDescription>
        </Alert>
      </ModalContent>
    </ModalContainer>
  );
}
