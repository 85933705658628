import {
  FormLabel,
  Box,
  Input as TextInput,
  FormControl,
  Textarea as TextArea,
  FormHelperText,
  FormErrorMessage,
  PinInput,
  PinInputField,
  HStack,
  InputGroup,
  Tag,
  TagLabel,
  TagCloseButton,
  Wrap,
  InputLeftElement,
} from "@chakra-ui/react";
import { Select as SelectInput } from "@chakra-ui/select";
import omit from "lodash.omit";
import React, { useState } from "react";

const Input = ({
  label,
  isInvalid,
  helperText,
  error,
  children,
  isValid,
  isRequired,
  ...props
}) => {
  return (
    <Box
      maxW={props.maxW || "400px"}
      w={props.w || { base: "90vw", md: "70vw" }}
      m="auto"
    >
      <FormControl isRequired={isRequired} isInvalid={isInvalid}>
        <FormLabel fontSize={"14px"} fontWeight={"600"}>
          {label}
        </FormLabel>
        <InputGroup>
          <TextInput {...props} p="10px" />
          {children}
        </InputGroup>
        {helperText && (
          <FormHelperText color={isValid ? "green.900" : props.color}>
            {helperText}
          </FormHelperText>
        )}
        {isInvalid && error && <FormErrorMessage>{error}</FormErrorMessage>}
      </FormControl>
    </Box>
  );
};

export const Textarea = props => {
  return (
    <Box
      maxW={props.maxW || "400px"}
      w={props.w || { base: "90vw", md: "70vw" }}
      m="auto"
    >
      <FormControl isInvalid={props.isInvalid}>
        <FormLabel fontSize={"14px"} fontWeight={"600"}>
          {props.label}
        </FormLabel>
        <InputGroup>
          <TextArea {...props} p="10px"></TextArea>
          {props.children}
        </InputGroup>
        {props.helperText && (
          <FormHelperText>{props.helperText}</FormHelperText>
        )}
        {props.isInvalid && props.error && (
          <FormErrorMessage>{props.error}</FormErrorMessage>
        )}
      </FormControl>
    </Box>
  );
};

export const Select = props => {
  return (
    <Box w={{ base: "90vw", md: "70vw" }} maxW="400px">
      <FormControl isInvalid={props.isInvalid}>
        <FormLabel fontSize={"14px"} fontWeight={"600"}>
          {props.label}
        </FormLabel>
        <SelectInput {...props}>
          {props.children ||
            props.options?.map(opt => (
              <option key={opt} value={opt}>
                {opt}
              </option>
            ))}
        </SelectInput>
        {props.helperText && (
          <FormHelperText>{props.helperText}</FormHelperText>
        )}
        {props.isInvalid && props.error && (
          <FormErrorMessage>{props.error}</FormErrorMessage>
        )}
      </FormControl>
    </Box>
  );
};

export const OTPInput = (props, { length = 5 }) => {
  return (
    <Box w={{ base: "90vw", md: "70vw" }} maxW="400px">
      <FormControl
        {...omit(props, ["isInvalid", "helperText", "error"])}
        className="otp-input"
        isInvalid={props.isInvalid}
      >
        <HStack justifyContent={"center"}>
          <PinInput {...props.otpInput} otp size={"lg"}>
            {Array.from(Array(length).keys()).map(key => (
              <PinInputField {...props.otpInputField} key={key} />
            ))}
          </PinInput>
        </HStack>
        {props.helperText && (
          <FormHelperText>{props.helperText}</FormHelperText>
        )}
        {props.isInvalid && props.error && (
          <FormErrorMessage>{props.error}</FormErrorMessage>
        )}
      </FormControl>
    </Box>
  );
};

/**
 * Represents a tag added to the list. Highlighted with a close button for removal.
 */
export const Chip = ({ label = "", onClose = label => {}, ...props }) => (
  <Tag borderRadius="full" variant="solid" {...props}>
    <TagLabel>{label}</TagLabel>
    <TagCloseButton onClick={() => onClose && onClose(label)} />
  </Tag>
);

export const ChipList = ({
  tags = [],
  setTags = tags => {},
  bg = "brand.secondary",
}) => {
  const handleClose = label => {
    setTags(tags.filter(tag => tag !== label));
  };
  return (
    <Wrap spacing={1}>
      {tags.map(tag => (
        <Chip key={tag} label={tag} onClose={handleClose} bg={bg} />
      ))}
    </Wrap>
  );
};

export const ChipInput = ({ tags, setTags, ...props }) => {
  const [chip, setChip] = useState("");
  const keyDown = event => {
    if (["Enter", "Tab", ","].includes(event.key)) {
      if (!tags.find(tag => tag === chip)) {
        event.preventDefault();
        setTags([...tags, chip]);
        setChip("");
      }
    }
  };

  return (
    <Box
      maxW={props.maxW || "400px"}
      w={props.w || { base: "90vw", md: "70vw" }}
      m="auto"
    >
      <FormControl isInvalid={props.isInvalid}>
        <FormLabel fontSize={"14px"} fontWeight={"600"}>
          {props.label}
        </FormLabel>
        <InputGroup
          border="1px solid"
          borderRadius={"md"}
          borderColor={"inherit"}
          display={"block"}
          p="10px 5px"
        >
          <InputLeftElement pos={"unset"} h="fit-content" w="fit-content">
            <ChipList tags={tags} setTags={setTags} />
          </InputLeftElement>
          <TextArea
            value={chip}
            onKeyDown={keyDown}
            onChange={event => setChip(event.target.value)}
            variant={"unstyled"}
            p={"0"}
            {...props}
          ></TextArea>
        </InputGroup>
        {props.helperText && (
          <FormHelperText>{props.helperText}</FormHelperText>
        )}
        {props.isInvalid && props.error && (
          <FormErrorMessage>{props.error}</FormErrorMessage>
        )}
      </FormControl>
    </Box>
  );
};
export default Input;
