import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { Home, Share, Privacy, PageNotFound } from "./pages";
import "./styles/index.scss";
import { useEffect } from "react";
import { ApolloProvider } from "@apollo/client";
import client from "./api";
import { QueryClient, QueryClientProvider } from "react-query";

function App() {
  useEffect(() => {
    localStorage.setItem("chakra-ui-color-mode", "light");
  }, []);

  return (
    <QueryClientProvider client={new QueryClient()}>
      <ApolloProvider client={client}>
        <Router>
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/share/:id" component={Share} exact />
            <Route path="/privacy" component={Privacy} />
            <Route path="*" component={PageNotFound} />
          </Switch>
        </Router>
      </ApolloProvider>
    </QueryClientProvider>
  );
}

export default App;
