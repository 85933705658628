import {
  Box,
  Center,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import vision from "../../../assets/icons/vision.png";
import mission from "../../../assets/icons/mission.png";
import React from "react";
import "../../../styles/components/goals.scss";

const goals = [
  {
    icon: vision,
    heading: "vision",
    text: "Empowering Africa's Future, One Skill at a Time",
  },
  {
    icon: mission,
    heading: "mission",
    text: "Bridging skills gaps through immersive game-based solutions for hybrid skills training, employability, and customer engagement",
  },
];
const Goals = () => {
  return (
    <Center className="goals">
      <Stack
        direction={["column", "row"]}
        gap={["35px", "35%"]}
        className="goals__items"
      >
        {goals.map((goal) => (
          <Flex key={goal.heading} className="goals__item">
            <Flex className="goals__item__header">
              <Box className="goals__item__image">
                <Image src={goal.icon} />
              </Box>
              <Heading className="goals__item__heading">{goal.heading}</Heading>
            </Flex>
            <Text className="goals__item__text">{goal.text}</Text>
          </Flex>
        ))}
      </Stack>
    </Center>
  );
};

export default Goals;
