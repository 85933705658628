import { CircularProgress, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

const Progress = ({ loading }) => {
  const [count, setCount] = useState(0);
  const colors = ["#FFED00", "#009640", "#662483", "#BE1622", "#009FE3"];
  const color = colors[count];

  useEffect(() => {
    const counter =
      loading &&
      document.readyState &&
      setInterval(() => {
        setCount(Math.floor(Math.random() * 5) + 1);
      }, 1000);

    return () => clearInterval(counter);
    //eslint-disable-next-line
  }, [loading, document.readyState]);

  return (
    loading && (
      <Flex
        className={`modal-backdrop${loading ? " show" : ""}`}
        w="100vw"
        h="100vh"
        justifyContent={"center"}
        alignItems="center"
      >
        <CircularProgress
          w="100%"
          mx={"90vh"}
          color={color}
          isIndeterminate
          trackColor={"white"}
        />
      </Flex>
    )
  );
};

export default Progress;
