import React from 'react';
import { Box, Text, Heading, VStack } from '@chakra-ui/react';

const SuccessStory = ({ name, occupation, description }) => {
  return (
    <Box
      maxW="600px"
      mx="auto"
      mt="4"
      p="6"
      boxShadow="lg"
      borderRadius="md"
      bg="white"
    >
      <VStack align="start" spacing="4">
        <Heading as="h3" size="md">
          {name}
        </Heading>
        <Text fontSize="sm" color="gray.500">
          {occupation}
        </Text>
        <Text fontSize="md">
          {description}
        </Text>
      </VStack>
    </Box>
  );
};

export default SuccessStory;
