import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from "@apollo/client";

// Create an ApolloLink middleware to update the headers with the latest token
const authLink = new ApolloLink((operation, forward) => {
  // Get the updated token

  // Set the token in the headers
  operation.setContext(({ headers }) => ({
    headers,
  }));

  return forward(operation);
});

// Create an HttpLink for your GraphQL API endpoint
const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_BASEURL}/graphql`,
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({ addTypename: false }),
  defaultOptions: {
    mutate: {
      errorPolicy: "all",
      fetchPolicy: "no-cache",
    },
    query: {
      errorPolicy: "all",
      fetchPolicy: "no-cache",
    },
    watchQuery: {
      errorPolicy: "all",
      fetchPolicy: "no-cache",
    },
  },
});

const mutate = client.mutate;
const query = client.query;

export { query, mutate };

export default client;
