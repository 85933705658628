import { Box, Heading,  Button } from "@chakra-ui/react";
import React from "react";
import PictureSlide from "./PictureSlide";
import Donate from "../../../components/Donate";
import AccountInput from "../../../components/AccountInput";
const slides1 = [
  {
    image: "/images/1.jpg",
    link: "",
  },
  {
    image: "/images/2.jpg",
    link: "",
  },
  {
    image: "/images/3.jpg",
    link: "",
  },
  {
    image: "/images/4.jpg",
    link: "",
  },
  {
    image: "/images/5.jpg",
    link: "",
  },
  {
    image: "/images/6.jpg",
    link: "",
  },
  {
    image: "/images/7.jpg",
    link: "",
  },
  {
    image: "/images/8.jpg",
    link: "",
  },
  {
    image: "/images/9.jpg",
    link: "",
  },
  {
    image: "/images/10.jpg",
    link: "",
  },
];
const UpskillAfrica = () => {
  return (
    <Box>
      <Box p={40} px={10} pb={20}>
        <Heading
          fontSize={["4xl", "6xl"]}
          fontWeight="bold"
          textAlign="center"
          maxW="800px"
          mx="auto"
        >
          Upskill Africa Scholarship Program. Proudly Supported By Master Africa
        </Heading>
        <Heading
          fontSize={["sm", "lg"]}
          fontWeight="regular"
          textAlign="center"
        >
          Compete and Win a Scholarship Program to Learn a Skill of your choice
        </Heading>
      </Box>

      <Box w={"84%"} h={"75vh"} m={"3rem auto 10px"}>
        <iframe
          style={{
            borderRadius: "10px",
            height: "100%",
            width: "100%",
          }}
          width="560"
          height="315"
          src="https://www.youtube.com/embed/0TrC-pb-_CI?si=K4A5HMLqNg5hKmLg"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </Box>

      <PictureSlide
        data={slides1}
        caption={
          "Join the Upskill Africa Movement as we train millions of young people to learn a skill. It all starts with one person"
        }
      />

      <Donate
        trigger={
        <div style={{
            maxWidth:"300px",
            margin:"35px auto"
        }}>
                <Button
            px={20}
            mx="auto"
            py={8}
            _focus={{
              background: "#00cf5a",
            }}
            _hover={{
              background: "#029642",
            }}
            color={"white"}
            background={"#029642"}
          >
            Donate
          </Button>
        </div>
        }
      >
        <Box>
          <Heading
            fontSize={{
              md: "xl",
            }}
          >
            Account Name: Master Apprenticeship and Recruiting Technology Ltd
          </Heading>
          <AccountInput label={"UBA (USD)"} account={"3004094361"} />
          <AccountInput label={"UBA (NAIRA)"} account={"1026219724"} />
        </Box>
      </Donate>
    </Box>
  );
};

export default UpskillAfrica;
