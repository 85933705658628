import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Box, Button, Heading } from "@chakra-ui/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const breakpoints = {
  100: {
    slidesPerView: 1,
    spaceBetween: 5,
  },
  320: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
  480: {
    slidesPerView: 2,
    spaceBetween: 30,
  },
  640: {
    slidesPerView: 3.1,
    spaceBetween: 40,
  },
  900: {
    slidesPerView: 3,
    spaceBetween: 40,
  },
};

function PictureSlide({ data = [], caption }) {
  return (
    <Box py={7}>
      <Heading
        fontSize={["3xl", "5xl"]}
        fontWeight="bold"
        textAlign="center"
        padding={10}
        maxW="800px"
        mx="auto"
      >
        {caption}
      </Heading>
      <Box position="relative" mx="auto">
        <Swiper
          loop={true}
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          breakpoints={breakpoints}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          spaceBetween={50}
          className="mb-5"
          slidesPerView={5}
        >
          {data.map((d, i) => (
            <SwiperSlide key={i}>
              <Box
                style={{
                  backgroundImage: `url(${d.image})`,
                  backgroundSize: "cover",
                  width: "400px",
                  height: "300px",
                }}
                w={["90vw", "400px"]}
                h={["400px", "200px"]}
              ></Box>
            </SwiperSlide>
          ))}
        </Swiper>

        <Button
          position="absolute"
          top="50%"
          p={8}
          className="swiper-button-prev"
        ></Button>
        <Button
          position="absolute"
          top="50%"
          right={0}
          p={8}
          className="swiper-button-next"
        ></Button>
      </Box>
    </Box>
  );
}

export default PictureSlide;
