import { Box, Heading, Text, Button, Flex, Image } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import error404 from "../../assets/404.png";
import Header from "../../pages/Home/components/Header";
import "../../styles/pages/pagenotfound.scss";

const PageNotFound = ({ header = "", content = "" }) => {
  return (
    <Box className="page-404">
      <Header />
      <Flex
        // minH="100vh"
        justify="space-around"
        p="25px 10%"
        align="center"
        className="pagenotfound"
      >
        <Box maxW="500px">
          <Heading fontSize={["4xl", "55px"]}>
            {header || "Oops, Page not found"}
          </Heading>
          <Text py="20px" fontSize={["sm", "18px"]}>
            {content ||
              "Sorry the page you are looking for does not exist, if you think something is broken, report a problem."}
          </Text>
          <Link to={"/"}>
            <Button
              size="lg"
              p="4px 30px"
              borderRadius="50px"
              fontSize="14px"
              bg="#298267"
              color="#fff"
            >
              {"Return Home"}
            </Button>
          </Link>
        </Box>

        <Image src={error404} w={["100%", "500px"]} />
      </Flex>
    </Box>
  );
};
export default PageNotFound;
