import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import ios from "../../../assets/icons/ios.png";
import android from "../../../assets/icons/android.png";
import comingSoon from "../../../assets/coming-soon.png";
import "../../../styles/components/action.scss";

const actions = [
  {
    icon: ios,
    href: "#",
  },
  {
    icon: android,
    href: "https://play.google.com/store/apps/details?id=com.masterafrica.mobile",
  },
];

const Action = ({ header = "", content = "" }) => {
  return (
    <Center overflow="hidden" className="action">
      <Box className="action__main">
        <Flex className="action__inner">
          <VStack className="action__content">
            <Heading className="action__heading">
              {header || "Coming Very Soon To Your Screens!"}
            </Heading>
            <Text
              className="action__text"
              fontWeight={content ? "bold !important" : ""}
            >
              {content || "To be available on ios and android for Download!"}
            </Text>
            <Stack className="action__buttons">
              {actions.map((action, index) => (
                <Button
                  onClick={() => window.location.replace(action.href)}
                  className="action__button"
                  key={action.icon}
                >
                  <Image src={action.icon} />
                  <span className="">
                    {index === 0 ? "Will be Availble Soon" : " Download App"}
                  </span>
                </Button>
              ))}
            </Stack>
          </VStack>
          <Image
            display={{ base: "none", lg: "block" }}
            className="action__image"
            src={comingSoon}
          />
        </Flex>
      </Box>
    </Center>
  );
};

export default Action;
