import React from "react";
import icon from "../../../assets/svg/master-icon.svg";
import { Flex, Image, Text, IconButton } from "@chakra-ui/react";
import "../../../styles/components/copyright.scss";
import {
  FaEnvelope,
  FaInstagramSquare,
  FaLinkedin,
  FaPhone,
  FaTiktok,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";

const year = new Date().getFullYear();

const socials = [
  {
    icon: FaPhone,
    link: "tel:+2348133081773",
  },
  {
    icon: FaInstagramSquare,
    link: "https://www.instagram.com/masterforafrica",
  },
  {
    icon: FaYoutube,
    link: "https://www.youtube.com/@MasterForAfrica",
  },
  {
    icon: FaTiktok,
    link: "https://www.tiktok.com/@masterforafrica",
  },
  {
    icon: FaLinkedin,
    link: "https://ng.linkedin.com/company/master-africa",
  },
  {
    icon: FaTwitter,
    link: "https://twitter.com/masterforafrica",
  },
  {
    icon: FaEnvelope,
    link: "mailto:hello@masterafrica.com",
  },
];

const Copyright = () => {
  return (
    <Flex id="copyright" className="copyright">
      <Flex gap={"8px"} alignItems={"center"} justifyContent={"space-between"}>
        {socials.map(social => (
          <IconButton
            size={"sm"}
            borderRadius={"full"}
            color={"white"}
            bgColor={"#34a853"}
            transition={"all 0.4s ease"}
            _hover={{
              cursor: "pointer",
              bgColor: "white",
              color: "#34a853",
              transform: "scale(1.5)",
            }}
            padding={"4px 8px"}
            as={social.icon}
            onClick={() => {
              window.open(social.link, "_blank");
            }}
          />
        ))}
      </Flex>
      <Flex className="copyright__text">
        <Image src={icon} />
        <Text>{year} Master. All rights reserved</Text>
      </Flex>
    </Flex>
  );
};

export default Copyright;
