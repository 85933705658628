import React from "react";
import {
  Box,
  Heading,
  Link,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { AttachmentIcon } from "@chakra-ui/icons";
import Header from "../Home/components/Header";

const PrivacyPolicy = () => {
  return (
    <Box>
      <Header />
      <Box py={10} px={{ base: 5, lg: 40 }}>
        <AttachmentIcon boxSize={"50px"} color={"#34a853"} />
        <Heading as="h2" size="xl" mt={6} mb={2}>
          Privacy Policy
        </Heading>
        <Text>
          Master Africa ("we," "our," or "us") is dedicated to safeguarding your
          privacy. This Privacy Policy outlines how we handle your personal
          information in our mobile application ("the App").
        </Text>
        <Heading as="h4" size="md" textAlign={"left"} mt={6} mb={2}>
          Age Restriction
        </Heading>
        <Text>
          The App is intended for use by individuals aged 18 and older. We do
          not knowingly collect or solicit personal information from individuals
          under the age of 18.
        </Text>
        <Heading as="h4" size="md" textAlign={"left"} mt={6} mb={2}>
          Information We Collect
        </Heading>

        <OrderedList
          type="1"
          textAlign={"left"}
          gap={"2em"}
          display={"flex"}
          flexDirection={"column"}
        >
          <ListItem>
            <b>In-App Search History</b>: We collect and store your in-app
            search history to improve the user experience and provide you with
            relevant content and recommendations.
          </ListItem>
          <ListItem>
            <b>Basic User Information</b>:
            <UnorderedList
              textAlign={"left"}
              gap={"1em"}
              display={"flex"}
              flexDirection={"column"}
            >
              <ListItem>
                <b>Name</b>: We collect your name to personalize your experience
                and address you by your name
              </ListItem>
              <ListItem>
                <b>Email</b>: We collect your email address for communication
                and account-related purposes.
              </ListItem>
              <ListItem>
                <b>Phone Number (optional)</b>: You have the option to provide
                your phone number. We may use your phone number for identity
                verification and to facilitate communication between Masters and
                Apprentices for apprenticeship applications.
              </ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            <b>Precise Location (optional)</b>: If you grant us permission, we
            may collect your precise location to facilitate the matching of
            Masters and Apprentices in specific locations and provide
            location-based services.
          </ListItem>
          <ListItem>
            <b>Calendar Access (optional)</b>: We may request access to your
            calendar to assist in scheduling events, such as apprenticeship
            sessions.
          </ListItem>
        </OrderedList>

        <Heading as="h4" size="md" textAlign={"left"} mt={6} mb={2}>
          Data Sharing
        </Heading>
        <Text>
          We do not share your personal data with third-party services,
          partners, or external organizations. Your data remains private and is
          used exclusively for the purposes stated in this policy.
        </Text>
        <Heading as="h4" size="md" textAlign={"left"} mt={6} mb={2}>
          User Data Access
        </Heading>
        <Text>
          You have full access to your personal data and the ability to manage
          it within the App. We respect your control over your information.
        </Text>
        <Heading as="h4" size="md" textAlign={"left"} mt={6} mb={2}>
          Data Security
        </Heading>
        <Text>
          We employ robust security measures to protect your data from
          unauthorized access, disclosure, or breaches. Your privacy and data
          security are of utmost importance to us.
        </Text>
        <Heading as="h4" size="md" textAlign={"left"} mt={6} mb={2}>
          Contact Us
        </Heading>
        <Text>
          If you have any questions, concerns, or requests regarding your data
          or this Privacy Policy, please contact us at:{" "}
          <Link color={"#34a853"} href="mailto:hello@masterafrica.com">
            hello@masterafrica.com
          </Link>{" "}
          or{" "}
          <Link color={"#34a853"} href="tel:+2347018070675">
            +234 701 807 0675
          </Link>
        </Text>
        <Heading as="h4" size="md" textAlign={"left"} mt={6} mb={2}>
          Changes to this Privacy Policy
        </Heading>
        <Text>
          We may update this Privacy Policy to reflect changes in our data
          practices. Please check this page periodically for updates. Your
          continued use of the App after any changes constitute your acceptance
          of the updated Privacy Policy.
        </Text>
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
