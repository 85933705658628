import axios from "axios";
import { useMutation } from "react-query";

export const useRegister = () => {
  const { mutateAsync: register, ...registerUserResults } = useMutation(
    async values => {
      return await axios.post(
        `${process.env.REACT_APP_BASEURL}/waitlist/add-user`,
        values,
        { withCredentials: true }
      );
    }
  );

  return { register, registerUserResults };
};
