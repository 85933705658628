import { Heading, Box } from "@chakra-ui/react";
import React from "react";

const FunLearning = () => {
  return (
    <div>
      <Heading
        className=""
        mt={"2em"}
        fontSize={["3xl", "5xl"]}
        fontWeight="bold"
        textAlign={"center"}
        maxW="800px"
        mx="auto"
      >
        Learning Should Be Fun. Over 300,000 views on TikTok.
      </Heading>

      <Box w={"84%"} position="relative" minH={"75vh"} m={"3rem auto 12rem"}>
        <blockquote
          className="tiktok-embed"
          cite="https://www.tiktok.com/@masterforafrica/video/7366123632554183954"
          data-video-id="7366123632554183954"
          style={{
            // maxWidth: "650px",
            minWidth: "325px",
          }}
        >
          <section>
            <a
              target="_blank"
              title="@masterforafrica"
              rel="noreferrer"
              href="https://www.tiktok.com/@masterforafrica?refer=embed"
            >
              @masterforafrica
            </a>{" "}
            Teacher Chike teaches at www.masterafrica.com
          </section>{" "}
        </blockquote>{" "}
      </Box>
    </div>
  );
};

export default FunLearning;
