import {
  Box,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import preview from "../../../assets/preview.png";
import React from "react";
import "../../../styles/components/offer.scss";
import group from "../../../assets/icons/Group Task.png";
import layout from "../../../assets/icons/Layout.png";
import classroom from "../../../assets/icons/Classroom.png";
import SuccessStory from "./SuccessStory";

const features = [
  {
    icon: group,
    heading: "Live Training",
    text: " Expert-led live training sessions: Learn from industry experts in real-time",
  },
  {
    icon: layout,
    heading: "Gamification",
    text: " Engage in interactive learning experiences that make skill-building fun",
  },
  {
    icon: classroom,
    heading: "In-Person Training",
    text: "Meet and learn from masters and apprentices in person",
  },
  {
    icon: classroom,
    heading: "Job readiness",
    text: "Acquire skills, confidence, and professional certificates to succeed in your chosen career",
  },
  {
    icon: classroom,
    heading: "Future-proof",
    text: "Stay ahead of the curve with continuous learning and skill development",
  },
];

const Offer = () => {
  return (
    <>
      <Box className="offer" background={"#ECF5ED"}>
        <Stack padding={"27px 8.9% 48px 11%"} gap={"92px"} direction={"row"}>
          <VStack flex={1} alignItems={"flex-start"}>
            <Heading className="offer__heading">What We Offer</Heading>
            <Text className="offer__content">
              {/* Master Africa is a Lagos based technology company operating an
              online marketplace for Creative Spaces made accessible to Creators
              and Masters. */}
              Master Africa is an Online Skills Marketplace offering Game-based
              and Hybrid training for enhanced Employability and
              Customer Engagement.
            </Text>
            <VStack alignItems={"flex-start"}>
              {features.map((feature) => (
                <Box className="offer__feature" key={feature.heading}>
                  <Flex
                    className="offer__feature__wrap"
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    gap={"30px"}
                  >
                    <Box className="offer__feature__image">
                      <Image src={feature.icon} w={"full"} />
                    </Box>
                    <VStack alignItems={"flex-start"}>
                      <Heading className="offer__feature__heading">
                        {feature.heading}
                      </Heading>
                      <Text className="offer__feature__text">
                        {feature.text}
                      </Text>
                    </VStack>
                  </Flex>
                </Box>
              ))}
            </VStack>
          </VStack>
          <Box display={["none", "block"]} transform={"scale(1.2)"} flex={1}>
            <Image src={preview} />
          </Box>
        </Stack>
      </Box>

      <Box>
        <Heading
          fontSize={["3xl", "5xl"]}
          fontWeight="bold"
          textAlign="center"
          padding={10}
        >
          Corporate Training
        </Heading>
        <Box w={"84%"} h={"75vh"} m={"3rem auto 12rem"}>
          <iframe
            style={{
              borderRadius: "10px",
              height: "100%",
              width: "100%",
            }}
            width="560"
            height="315"
            src="https://youtube.com/embed/maUMie4WMXE?si=ifszlmKB5FDw75Mr"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Box>
      </Box>
      <Box>
        <Heading
          fontSize={["3xl", "5xl"]}
          fontWeight="bold"
          textAlign="center"
          padding={10}
        >
          Tech Skills Training
        </Heading>
        <Box w={"84%"} h={"75vh"} m={"3rem auto 10px"}>
          <iframe
            style={{
              borderRadius: "10px",
              height: "100%",
              width: "100%",
            }}
            width="560"
            height="315"
            src="https://www.youtube.com/embed/yyywEkVcDbk?si=wnomwOUysXHARbCs"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Box>
        <Box p="4" mb="5rem">
          <Heading
            fontSize={["3xl", "5xl"]}
            fontWeight="bold"
            textAlign="center"
          >
            Success Story
          </Heading>
          <SuccessStory
            description={
              "“Because of Master Africa, I am able to pursue my passion for Ui/Ux design. Thank you Master Africa"
            }
            name={"- Damilare"}
            occupation={"Ui/Ux Designer"}
          />
        </Box>
      </Box>
      <Box>
        <Heading
          fontSize={["3xl", "5xl"]}
          fontWeight="bold"
          textAlign="center"
          padding={10}
        >
          Manual Skills Training
        </Heading>
        <Box w={"84%"} h={"75vh"} m={"3rem auto 10px"}>
          <iframe
            style={{
              borderRadius: "10px",
              height: "100%",
              width: "100%",
            }}
            width="560"
            height="315"
            src="https://youtube.com/embed/a86N9eSmLzk?si=lxogznqr3VFExRs6"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Box>
        <Box p="4" mb="5rem">
          <Heading
            fontSize={["3xl", "5xl"]}
            fontWeight="bold"
            textAlign="center"
          >
            Success Story
          </Heading>
          <SuccessStory
            description={
              "Master Africa helped me to learn hairstyling, now I work with a seasoned hairstylist. I am truly grateful!"
            }
            name={"- Adenike"}
            occupation={"Hairstylist"}
          />
        </Box>

        <Box w={"84%"} h={"75vh"} m={"3rem auto 10px"}>
          <iframe
            style={{
              borderRadius: "10px",
              height: "100%",
              width: "100%",
            }}
            width="560"
            height="315"
            src="https://youtube.com/embed/POjyBpT7DLs?si=lzps22M2hCzyZ7h5"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Box>
        <Box p="4" mb="5rem">
          <Heading
            fontSize={["3xl", "5xl"]}
            fontWeight="bold"
            textAlign="center"
          >
            Success Story
          </Heading>
          <SuccessStory
            description={
              "Thanks to Master Africa, I get to beautify dresses with my new found embroidery skill"
            }
            name={"- Precious Aniche"}
            occupation={"Embroidery"}
          />
        </Box>
      </Box>
      <Box>
        <Heading
          fontSize={["3xl", "5xl"]}
          fontWeight="bold"
          textAlign="center"
          padding={10}
        >
          Creative Skills Training
        </Heading>
        <Box w={"84%"} h={"75vh"} m={"3rem auto 10px"}>
          <iframe
            style={{
              borderRadius: "10px",
              height: "100%",
              width: "100%",
            }}
            width="560"
            height="315"
            src="https://youtube.com/embed/aF6_sOQR3Bc?si=mkvARFSLmTc7hoLu"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Box>
        <Box p="4" mb="5rem">
          <Heading
            fontSize={["3xl", "5xl"]}
            fontWeight="bold"
            textAlign="center"
          >
            Success Story
          </Heading>
          <SuccessStory
            description={
              "Master Africa helped me learn photography skills and land a job at a top studio!"
            }
            name={"- Emmanuel"}
            occupation={"Photographer"}
          />
        </Box>
        <Box w={"84%"} h={"75vh"} m={"3rem auto 10px"}>
          <iframe
            style={{
              borderRadius: "10px",
              height: "100%",
              width: "100%",
            }}
            width="560"
            height="315"
            src="https://youtube.com/embed/A8hUgBRtxeY?si=kYkC0VaTesUtONF-"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Box>
        <Box p="4" mb="5rem">
          <Heading
            fontSize={["3xl", "5xl"]}
            fontWeight="bold"
            textAlign="center"
          >
            Success Story
          </Heading>
          <SuccessStory
            description={
              "Master Africa fueled my fashion dreams and financial freedom!"
            }
            name={"- Nonye"}
            occupation={"Fashion Designer"}
          />
        </Box>
        <Box w={"84%"} h={"75vh"} m={"3rem auto 10px"}>
          <iframe
            style={{
              borderRadius: "10px",
              height: "100%",
              width: "100%",
            }}
            width="560"
            height="315"
            src="https://youtube.com/embed/iG4nH4mlVpA?si=JenkeVM5f4RSz-vQ"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Box>
        <Box p="4" mb="5rem">
          <Heading
            fontSize={["3xl", "5xl"]}
            fontWeight="bold"
            textAlign="center"
          >
            Success Story
          </Heading>
          <SuccessStory
            description={
              "Master Africa helped me find an apprentice and profit from my passion."
            }
            name={"- Mary Jay"}
            occupation={"Fashion Designer"}
          />
        </Box>
        <Box w={"84%"} h={"75vh"} m={"3rem auto 10px"}>
          <iframe
            style={{
              borderRadius: "10px",
              height: "100%",
              width: "100%",
            }}
            width="560"
            height="315"
            src="https://www.youtube.com/embed/Lk_Bmm9JtVI?si=gFGQDRnIlCq2gUZT"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Box>
        <Box p="4" mb="5rem">
          <Heading
            fontSize={["3xl", "5xl"]}
            fontWeight="bold"
            textAlign="center"
          >
            Success Story
          </Heading>
          <SuccessStory
            description={
              "Master Africa connected me with the right mentor, and now my music career is hitting the right notes!"
            }
            name={"- Franklin"}
            occupation={"Music Producer"}
          />
        </Box>
      </Box>
    </>
  );
};

export default Offer;
