import React, { useMemo, useState } from "react";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Offer from "./components/Offer";
import Form from "./components/Form";
import Goals from "./components/Goals";
import Action from "./components/Action";
import Footer from "./components/Footer";
import PictureSlide from "./components/PictureSlide";
import Copyright from "./components/Copyright";
import { creatorRoles, options as skills, softSkills } from "../../constants";
import Donate from "../../components/Donate";
import { Box, Heading, Button } from "@chakra-ui/react";
import AccountInput from "../../components/AccountInput";
import FAQ from "../../components/FAQ";
import MobileScreenSlide from "./components/MobileScreensSlide";
import FunLearning from "./components/FunLearning";
import CompletionSlider from "./components/CompletionSlider";
import LiveSessions from "./components/LiveSessions";
import UpskillAfrica from "./components/UpskillAfrica";

const slides2 = [
  {
    image: "/images/11.jpg",
    link: "",
  },
  {
    image: "/images/12.jpg",
    link: "",
  },
  {
    image: "/images/13.jpg",
    link: "",
  },
  {
    image: "/images/11.jpg",
    link: "",
  },
];


const slides4 = [
  {
    image: "/images/games/1.png",
    link: "",
  },
  {
    image: "/images/games/2.png",
    link: "",
  },
  {
    image: "/images/games/3.png",
    link: "",
  },
  {
    image: "/images/games/4.png",
    link: "",
  },
  {
    image: "/images/games/5.png",
    link: "",
  },
  {
    image: "/images/games/6.png",
    link: "",
  },
];
const slides3 = [
  {
    image: "/images/completion-4.jpg",
    link: "",
    caption:
      "Congratulations Precious Aniche! You've stitched your way to success! Completing embroidery training and getting employed with Diong Beads in partnership with Master Africa. Your skills are truly precious!",
  },
  {
    image: "/images/completion.jpg",
    link: "",
    caption:
      "Congratulations Emmanuel Chinedu Ojini on your Master Africa Certificate of Completion in Photography, in partnership with Instabooth Studios! Training and getting a job, DONE We're proud of you!",
  },
  {
    image: "/images/completion-2.jpg",
    link: "",
    caption:
      "Congratulations to “Franklin Oladapo Ajayi” on receiving the Certificate of Completion for the Beat Making course with Master Africa & Jaypro Studios! Your rhythm is just getting started!",
  },
  {
    image: "/images/completion-3.jpg",
    link: "",
    caption:
      "Congratulations to Ajani Julius Oluwadamilare on completing the UI/UX Design program with Master Africa, in partnership with Daniel Aj! Your dedication and hard work have earned you this prestigious certificate. Welcome to the league of skilled designers!",
  },
];

const Home = () => {
  const [passion, setPassion] = useState("");
  const [options, setOptions] = useState(skills);

  const props = useMemo(
    () => ({
      passion,
      options,
      softSkills,
      creativeSkill: creatorRoles,
      setPassion,
      setOptions,
    }),
    [options, passion, setPassion, setOptions]
  );

  return (
    <>
      <Header />
      <Hero {...props} />

      <MobileScreenSlide
        data={slides4}
        caption={"Game On"}
        subCaption={"Compete to become the MAG (Master Africa Games) Champion"}
      />

      <FunLearning />
      <CompletionSlider slides={slides3} />
      <LiveSessions />
      <UpskillAfrica />


      <PictureSlide
        data={slides2}
        caption={"Earn money renting out training spaces."}
      />
      <Form {...props} />
      <Offer />
     
      <Box display="flex" my={10} justifyContent="center" mx="auto">
        <Donate
          trigger={
            <Button
              px={20}
              py={8}
              _focus={{
                background: "#00cf5a",
              }}
              _hover={{
                background: "#029642",
              }}
              color={"white"}
              background={"#029642"}
            >
              Donate
            </Button>
          }
        >
          <Box>
            <Heading
              fontSize={{
                md: "xl",
              }}
            >
              Account Name: Master Apprenticeship and Recruiting Technology Ltd
            </Heading>
            <AccountInput label={"UBA (USD)"} account={"3004094361"} />
            <AccountInput label={"UBA (NAIRA)"} account={"1026219724"} />
          </Box>
        </Donate>
      </Box>
      <Goals />
      <Action />
      <FAQ />
      <Footer />
      <Copyright />
    </>
  );
};

export default Home;
