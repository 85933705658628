import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";

const Donate = ({ children, trigger }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <div className="" onClick={onOpen}>
        {trigger || (
          <Button
            _focus={{
              background: "#00cf5a",
            }}
            _hover={{
              background: "#029642",
            }}
            color={"white"}
            background={"#029642"}
          >
            Donate
          </Button>
        )}
      </div>
      <Modal size="xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent position="relative">
          <ModalHeader>Donate</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{children}</ModalBody>

          <ModalFooter>
            <Button
              background={"#00cf5a"}
              color="white"
              mr={3}
              onClick={onClose}
            >
              Done
            </Button>
            <Button onClick={onClose} variant="ghost">
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Donate;
